import React, {useEffect, useState, Fragment} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const ManageReferral = () => {

    const [referral, setreferral] = useState('');   
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const getDate = (date) => new Date(date).toDateString();

    const { isLoading: isApprovingreferral, mutate: approvereferral } = useMutation(        
        async () => {
          return await apiClient.post(`/api/approve-referral/${referral.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

    const { isLoading: isDceliningreferral, mutate: declinereferral } = useMutation(        
        async () => {
          return await apiClient.post(`/api/decline-referral/${referral.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
    
    useEffect(() => {
        window.addEventListener('adminhistoryreferralSelected', function (e) {
            let selectedreferral = localStorage.getItem('adminhistoryreferralSelected');
            if (selectedreferral != null) {
                selectedreferral = JSON.parse(selectedreferral); 
                setreferral(selectedreferral);
            }
        }, false);
    })
  return (
    <div>
        <div id="admin-referral-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height:"480px"}}>
                {referral !== '' &&
                <div className="content">
                <a href="/#" className="d-flex py-1 pb-4">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-person-circle font-18 color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">{referral.inviter.email}</h5>
                        <p className="mb-0 font-11 opacity-70">{getAmount(referral.amount)}</p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                    {referral.status === 'pending' && <h4 className="pt-1 font-14 mb-n1 color-yellow-dark">{referral.status}</h4>}
                    {referral.status === 'approved' && <h4 className="pt-1 font-14 mb-n1 color-green-dark">{referral.status}</h4>}
                    {referral.status === 'completed' && <h4 className="pt-1 font-14 mb-n1 color-theme">{referral.status}</h4>}
                    {referral.status === 'declined' && <h4 className="pt-1 font-14 mb-n1 color-red-dark">{referral.status}</h4>}
                    <p className="mb-0 font-11"> {getDate(referral.created_at)}</p>
                    </div>
                </a>
                <div className="row">
                    <strong className="col-5 color-theme">referral Amount</strong>
                    <strong className="col-7 text-end">{getAmount(referral.amount)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">referral Date </strong>
                    <strong className="col-7 text-end">{getDate(referral.created_at)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">Upliner Email</strong>
                    <strong className="col-7 text-end">{referral.inviter.email}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div> 

                    <strong className="col-5 color-theme">Downliner Email </strong>
                    <strong className="col-7 text-end color-highlight">{referral.inviter.email}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>
                                      
                    
                    {referral.status === 'pending' &&
                    <Fragment>
                        <div className="col-6">
                            <div onClick={approvereferral} className="btn btn-s btn-full gradient-green shadow-bg shadow-bg-xs">
                            {isApprovingreferral ? <span>Approving...</span>: <span>Approve</span>} 
                            </div>
                        </div>
                        <div className="col-6">
                            <div onClick={declinereferral} className="btn btn-s btn-full gradient-red shadow-bg shadow-bg-xs">
                                {isDceliningreferral ? <span>Declining...</span>: <span>Decline</span>} 
                            </div>
                        </div>
                    </Fragment>                    
                    }
                </div>
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ManageReferral