import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import apiClient from "../../http-common";
import { useMutation } from "react-query";
import QRCode from "react-qr-code";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  import {CopyToClipboard} from 'react-copy-to-clipboard';

  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);

const Payment = () => {

    let tranx_id = (new URLSearchParams(window.location.search)).get("tranx_id");
    let amount = (new URLSearchParams(window.location.search)).get("amount"); 
    let wallet = (new URLSearchParams(window.location.search)).get("wallet"); 
    let address = (new URLSearchParams(window.location.search)).get("address"); 
    const [copied, setCopied] = useState(false);
    
    let navigate = useNavigate();

    const { isLoading: isSendingRequest, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/verify-investment/${tranx_id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            setTimeout(() => {
              window.location.href = '/';
            }, 2000);
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        document.title = 'USER PAYMENT';
          setTimeout(() => {
              if (!amount) {
                  navigate('/invest');
              }
          }, 1000);
      })

  return (
    <div  id="page-content">
         <div className="card card-style bg-fade-blue border border-fade-blue alert alert-dismissible show fade p-0 mb-3">
           <div className="content my-3">
               
               <div className="d-flex">
                   <div className="align-self-center no-click">
                       <i className="bi bi-info-square font-33 color-blue-dark d-block"></i>
                   </div>
                   <div className="align-self-center no-click">
                       <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                           kindly pay the sum of {getAmount(amount)} to the provided {wallet} address below<br/> you can copy the address or scan the QR code from your app to pay.
                       </p>
                   </div>
               </div>
           </div>
       </div>
        <div className="card card-style px-0 bg-white">
            <div className="content text-center">
                 <div className="pb-2 font-18">{getAmount(amount)} {wallet}</div>
                <div className="mb-3">
                <CopyToClipboard text={address}
                  onCopy={() => setCopied(true)}>
                  <span> <span className='color-theme bg-theme p-2 border rounded-m'>{address}</span>  {copied ? <span className='color-green-dark ml-3 font-18 p-2 border rounded-m'>Copied!</span> :<span className="material-icons-sharp color-theme p-2 border rounded-m" style={{fontSize: "27px", transform: "translate(5px, 8px)"}}>content_copy</span>}</span>
                </CopyToClipboard>
                </div>
                <QRCode value={address} />
            </div>
        </div>
        <div className='px-3'>
            <div onClick={postInvestment} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                {isSendingRequest? <span>submitting...</span>
                :
                <span>I have completed this payment</span>
                }                            
            </div>
        </div>
        
    </div>
  )
}

export default Payment