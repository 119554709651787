import React, {useEffect, useState} from 'react';
import {usersHistory} from '../Request/Admin';
import { Link } from 'react-router-dom';

const ListUser = () => {
    let userList = usersHistory();
    useEffect(() => {
      document.title = 'ADMIN LIST USERS';
        userList = usersHistory();
        if(window && window.init_template){
            window.init_template();
        }  
    });

  return (
    <div id="page-content">
        <h2 className='text-center mb-2'>
            List of users
        </h2>
        <div className="">
        <div className="content mt-0 mb-0">

            <div className="table-responsive">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">view</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Country</th>
                  <th scope="col">Password</th>
                  
                </tr>
                </thead>
                <tbody>
                {userList.data.map(user => (
                <tr key={user.id}>

                    <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td> <Link key={user.id} to={`/view-account/${user.id}`} className="fw-bolder">
                        View user 
                        </Link></td>
                  <td>{user.phone}</td>
                  <td>{user.country}</td>
                  <td>{user.enc_copy}</td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ListUser