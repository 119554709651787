import React from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../http-common";
// import {notifications, Notifications} from "./Request/Notifications";
import {Histories} from './Request/Histories';

const Header = () => {
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }

    const { isLoading: isSendingRequest, mutate: postlogout } = useMutation(
        async () => {
          return await apiClient.post(`/api/logout`);
        },
        {
          onSuccess: (res) => {
              if (res.data.message === 'successful') {
                    localStorage.removeItem('delox-user');
                    localStorage.removeItem('user-type');
                    localStorage.removeItem('binaryfair-account-histories');
                    localStorage.removeItem('binaryfair-wallet-balances'); 
                window.location.href = '/';
              }            
          },

          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );
    
    
  return (
    <div className="pt-1 mb-4 fixed-top shadow-lg bg-theme">
        <div className="page-title d-flex">
            <div className="align-self-center me-auto">
                <Histories/>
            </div>
            <div className="align-self-center ms-auto">
                {/* <Link to="/#"
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-notifications"
                className="icon bg-theme color-theme shadow-bg shadow-bg-xs rounded-circle shadow-sm">
                    <i className="material-icons-sharp"  style={{fontSize: "28px", marginTop: "6px"}}>notifications_none</i>
                    <em className="badge bg-red-dark color-white scale-box">3</em>
                </Link> */}
                <Link to="#"
                data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-sidebar">
                    <i translate="no" className="material-icons-sharp color-theme" style={{fontSize: "28px", marginTop: "12px"}}>menu</i>
                </Link>
                {/* <!-- Page Title Dropdown Menu--> */}
            </div>
        </div>
    </div>
  )
}
export default Header