import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import { accountBalance, referralBalance, bonusBalance, currentInvestment } from '../Request/Wallets';
import {currentEarning} from '../Request/Wallets';
import Clock from '../Investment/Clock';
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";

const Index = () => {

    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());
    const [currentInvested, setcurrentInvested] = useState(currentInvestment());


       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

      const newCurrentInvestment = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.current_investment;
        }
        return balance;
      }


    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('TURBO ASSETS LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('TURBO ASSETS LIMITED-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setreferralBalance(newreferralBalance());
        setbonusBalance(newbonusBalance());
        setcurrentInvested(newCurrentInvestment());
        
  }, false);
    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
   return (
<div id="page-content">
    {/* <!-- Main Card Slider--> */}
    <div className={`splide ${matches ? 'single-slider': 'triple-slider'} slider-no-dots  slider-no-arrows slider-visible mt-n8`} id="single-slider-1">
        <div className="splide__track">
            <div className="splide__list">
                <div className="splide__slide">
                    <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1648998258/fitteddeloxcard.png)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet" aria-controls="fundingWallet">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="fundingWallet"><h2 className="color-theme font-26">{getAmount(accBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Earnings Wallet</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>

                <div className="splide__slide">
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1648998258/fitteddeloxcard.png)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#referralBalance" aria-controls="referralBalance">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="referralBalance"><h2 className="color-theme font-26">{getAmount(refBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Referral Wallet</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>


                <div className="splide__slide">
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1648998258/fitteddeloxcard.png)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#bonusBalance" aria-controls="bonusBalance">Click for Amount</a>
                                </h1>
                                <div className="collapse" id="bonusBalance"><h2 className="color-theme font-26">{getAmount(currentInvested)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Current Investment</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    {/* <!-- Quick Actions --> */}
    {accountType !== 'admin' && 
    <div className="content py-2">
        <div className="d-flex text-center">
            <div className="me-auto">
                <Link to="/invest" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>analytics</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Invest</h6>
            </div>
            <div className="m-auto">
                <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>money_off_csred</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Withdraw</h6>
            </div>
            <div className="m-auto">
                <Link to="/referral" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>diversity_2</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Referral</h6>
            </div>
            <div className="ms-auto">
                <Link to="/market" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>candlestick_chart</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">market</h6>
            </div>
        </div>
    </div> 
    }
        
    <div className='container'>
        <div className="row">
            {accountType === 'admin' ?
            <div className="col-12 my-4">
                <div className="card text-center p-6">
                    <h4 className='text-success'>Currently using Admin account for <br/> {user.name}</h4>
                </div>            
            </div> :
            <div className="col-12 mt-n2 mb-4 text-center">
                {currentEarning() > 0 && 
                <Clock/>
                }
            </div>  
            }     
        </div>  
        {/* <TechnicalAnalysis symbol='BITSTAMP:BTCUSD' colorTheme={currentTheme} width="100%"></TechnicalAnalysis> */}
    </div>     
    <Analysis/>   
</div>
  )
}

export default Index